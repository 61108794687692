<template>
    <div>
        <div class="text-white rounded-md">
            <h1 class="text-2xl px-3 font-semibold">Редактирование границ города</h1>
            <div class="flex justify-between items-center my-5 px-3">
                <label class="mr-3">WKT</label>
                <input id="coords" class="border text-black mx-0 w-[267px] h-[40px]" type="text" @input="searchPolygon" placeholder="WKT" v-model="coordinates">
                <i class="ri-file-copy-line cursor-pointer" @click="copy()"></i>
            </div>
            <div class="text-[#FFA500] text-[13px] text-center" v-if="!coordinatesLatLon">
                Вы точно хотите сохранить изменения. <br/> Перед сохранения скопируйте данные полигон <br/>чтобы если возникнет ошибка смогли восстановить
            </div>
            <div class="flex my-4 px-3">
                <!-- <div class="bg-white text-black rounded-sm mr-2 py-1 px-2 cursor-pointer"><i class="ri-search-line"></i></div> -->
                <button class="bg-white text-black rounded-sm p-1 ml-auto" @click="cancelEdit" :disabled="coordinatesLatLon" :style="coordinatesLatLon ? {backgroundColor: 'darkgrey'} : {}" >Отменить изменения</button>
                <button class="bg-black/40 text-white rounded ml-2 p-1" :disabled="coordinatesLatLon" :style="coordinatesLatLon ? {backgroundColor: 'darkgrey', color: 'black'} : {}"  @click="savePolygon">Сохранить</button>
            </div>
        </div>
    </div>
</template>
<!-- @click="$emit('update')" -->
<script>
export default {
    props: ['village', 'drawResult'],
    data() {
        return {
            coordinates: [],
            coordinatesLatLon: true
        }
    },
    methods: {
        searchPolygon() {
            let coordinates = this.village
            coordinates.latlngs = this.coordinates ? JSON.parse(this.coordinates) : this.coordinates
            setTimeout(() => {
                this.coordinatesLatLon = false
                this.$emit('cancelEdit', coordinates)
            }, 30);
        },
        savePolygon() {
            let save = confirm("Вы дествитель хотите изменить?");
            if(save) {
                this.$emit('postPolygon', JSON.parse(this.coordinates))
            }
        },
        copy(){
            // document.querySelector('.leaflet-draw-actions li:first-child a').click()
            // console.log(this.propPolygon);
            const copyText = document.querySelector('#coords')
            copyText.select();
            copyText.setSelectionRange(0, 99999); /* For mobile devices */

            /* Copy the text inside the text field */
            navigator.clipboard.writeText(copyText.value);
        },
        cancelEdit() {
            this.coordinatesLatLon = true
            this.coordinates = JSON.stringify(this.village.latlngs)
            this.$emit('cancelEdit', this.village)
        }
    },
    watch: {
        village(newValue) {
            this.coordinates = JSON.stringify(newValue.latlngs)
        },
        drawResult(newValue) {
            this.coordinates = []
            this.coordinates = JSON.stringify(newValue)
            this.coordinatesLatLon = false
        }
    }
}
</script>