<template>
  <div v-hotkey="keymap">
    <l-map id="map" style="height: 100vh;" ref="map" :zoom="zoom" :center="center" :maxZoom="20"
      @update:zoom="zoomUpdated" @update:bounds="boundsUpdated" :options="{ zoomControl: false }"   @moveend="zoom >= 18 ? onMoveEnd() : ''"
      @contextmenu="handle($event)">
      <l-routing-machine ref="route" :waypoints="waypoints" ></l-routing-machine>
      <l-marker @click="isMarkerActive = false" v-if="isMarkerActive" :lat-lng="center">
      </l-marker>
      <l-marker @click="searchAddresWithCoor" v-if="showContext.lat" :lat-lng="showContext">
        <l-icon >
          <div class="w-[80px] rounded-md border-2 border-black text-center p-[5px] bg-white">
            Что здесь?
          </div>
        </l-icon>
      </l-marker>
      <div class="search_division" style="position: fixed; top: 0; left: 15px;">
        <div class="container ml-0">
          <div class="w-auto mt-[10px]">
            <v-select
                class="w-[270px] text-black bg-white"
                :options="divisions"
                :reduce="options => options"
                v-model="center"
                label="name"
                @input="changeStreetId"
                />
          </div>
        </div>
      </div>
      <div class="search" style="position: fixed; top: 0; right: 0; ">
        <div class="container ml-0">
          <div class="w-auto mt-[20px] ml-[15px]">
            <search-select :searchAddress="searchAddress" @setWaypoints="setWaypoints" :isMarkerActive="isMarkerActive" @handleChangeDataCenter="handleChangeDataCenter"></search-select>
          </div>
        </div>
      </div>
      <l-tile-layer :url="url" :options="{ maxZoom: 20 }" />




      <v-marker-cluster>
        <l-marker v-for="address in addresses" :key="address.id" :lat-lng="[address.lat, address.lng]"
           @click="addLocSpeedaddress || addHomeSteets ? '' : selectHouse(address.id)">
          <l-icon v-if="zoom <= 16" class-name="someExtraClass">
            <div class="headline w-6 h-6 rounded-full bg-black/70 text-white flex justify-center items-center">
              {{ address.name }}
            </div>
          </l-icon>
          <l-icon v-else-if="zoom >= 16" class="someExtraClass">
            <img src="../../assets/img/house-black.png" alt="" srcset="">
          </l-icon>
        </l-marker>

        
        <l-marker v-for="fastAddres in fastAddresses" :key="fastAddres.id + 'fast'" :lat-lng="[fastAddres.lat, fastAddres.lng]"
           @click="addFastAddress ? '' : selectFastHouse(fastAddres.id)" @dragend="markerDragEndAddress" :draggable="fastAddres.draggable">
          <l-icon class-name="someExtraClass">
            <div class="headline w-6 h-6 rounded-full bg-pink-500 flex justify-center items-center">
              <img src="../../assets/img/house-black.png" alt="" srcset="">
            </div>
          </l-icon>
        </l-marker>
      </v-marker-cluster>

       
      
      

        

      <div v-for="polygon in polygons" :key="polygon.id">
        <l-marker @click="selectVillages(polygon)" :lat-lng="polygon" ref="marker"  @mouseover="openPopup">
          <l-icon>
            <l-popup 
            :content="
            `${polygon.name}, Согдийская область, район ${polygon.city_name}, ${polygon.village_type_name}
            ${'<br/>'}ID: ${polygon.id}`"></l-popup>
            <marker-icon :color="polygon.colorIcon" :type="polygon.village_type_id"/>
          </l-icon>
        </l-marker>
      </div>


      <div v-for="polygon in polygons" :key="polygon.id + 'polygon'">
        <div v-if="homeCenterPolygon">
          <l-polygon :lat-lngs="polygon.latlngs ? polygon.latlngs : []" :color="polygon.color" :weight="polygon.weight"
            :fillOpacity="0" />
        </div>
      </div>
      
      <div v-if="editZones">
          <polygonCustom :map="map.mapObject" v-model="latlng" :options="districts" :fillOpacity="''" ref="editPolygon" @open="editPolygonWithIndex"/>
      </div>

      <div v-if="editedZone">
        <zonaCustom :map="map.mapObject" ref="zonaСhoosing" :options="districts" v-model="zona"/>
      </div>

      <div v-if="addDistrict">
        <addCustomPolygon v-model="addDistrictsData" :map="map.mapObject" ref="addDistricts"/>
      </div>
      <div v-if="addDistrict">
        <showPolygon :map="map.mapObject" ref="showPolygon" :options="districts"/>
      </div>

      <div v-if="lineIsActive">
        <createLine :map="map.mapObject" ref="Line"/>
      </div>

    </l-map>
    <tool-bar-map class="flex" :routerIsActive="routerIsActive" :lineIsActive="lineIsActive" :addFastAddress="addFastAddress" :addLocSpeedaddress="addLocSpeedaddress" :editStreets="editStreets" :editHomes="editHomes" :addHomeSteets="addHomeSteets" 
      :addDistrict="addDistrict" @createDistrict="createDistrict" :editedZone="editedZone" @removeDistricts="removeDistricts"
      :editZones="editZones" :editVillageCity="editVillageCity" :homeCenterPolygon="homeCenterPolygon" @zoomPlus="zoomPlus" 
      @zoomMinus="zoomMinus" @addHome="addHome" @addLocation="addLocation"
      @eyeAddreses="eyeAddreses" @addFast="addFast" @editRouter="editRouter" @lineCreate="lineCreate" @showDistricts="showDistricts"
      @addVillage="addVillage" @editPolygon="editPolygon" @addStreet="addStreet" @eyeDistricts="eyeDistricts"
      @eyeFastAdress="eyeFastAdress" @editFast="editFast" @editZone="editZonesInDist" @styleMap="styleMap" @polygonDisabled="polygonDisabled" />
    <forms-panel :panel="selectHome" @close="close">
      <form-home :editHouse="editHouse" :selectHome="selectHome" @change="homeDataChange" :village_id="village_id"
        @update="getAddress" @close="selectHome = false" />
    </forms-panel>
    <forms-panel :panel="editVillageCity" @close="close(), update()">
      <form-city @cancelEdit="cancelEdit" :drawResult="drawResult" :village="village" @update="update" @postPolygon="postPolygon" />
    </forms-panel>
    <forms-panel :panel="addDistrict" @close="close(), update()">
      <form-district-add @close="close(), update()" :village_id="village_id" :addDistrictsData="addDistrictsData"/>
    </forms-panel>
    <forms-panel :panel="editDistrict" @close="close, update()">
      <form-district-edit @postDistrict="postDistrict" :districtData="districtData" :latlng="latlng"
        @update="update" />
    </forms-panel>
    <forms-panel :panel="editStreets" @close="close">
      <form-street :dist_id_street="dist_id_street" :editStreets="editStreets" :village_id="village_id"
        @close="editStreets = false" />
    </forms-panel>
    <forms-panel classTop="top-[40px]" :panel="addLoc" @close="close">
      <form-add :coor="houseCoordinates" :addLoc="addLoc" :addAny="addAny" @addressAdded="addressAdded"
        :outsidePolygon="outsidePolygon" :village_id="village_id" @centerToAdressError="centerToAdressError"
        @addHouse="addHouse" @removeCircleMarker="removeCircleMarker" @addCircleMarker="addCircleMarker"
        @click="addLoc = false" />
    </forms-panel>
    <forms-panel :panel="addVilldage" @close="close">
      <form-villages :propVillage="propVillage" :addVilldage="addVilldage" @update="update" />
    </forms-panel>
    <forms-panel :panel="addFastAddress" @close="close(), closeFormAddress()">
      <form-address :fastAddressCoor="fastAddressCoor" :addFastAddress="addFastAddress" :addAny="addAny"
        @fastAddressAdded="fastAddressAdded" :outsidePolygon="outsidePolygon" :village_id="village_id"
        @addCircleMarker="addCircleMarker" @removeFastMarker="removeFastMarker" @update="update"
        @close="closeFormAddress" />
    </forms-panel>
    <forms-panel :panel="editFastAddress" @close="close">
      <form-address-edit :editFastHouse="editFastHouse" :editFastAddress="editFastAddress" :village_id="village_id"
        @change="fastAddressDataChange" @refresh="eyeFastAdress" @close="editFastAddress = false" />
    </forms-panel>
    <forms-panel :panel="editedZone" @close="close(), update()">
      <form-zone :village_id="village_id" :addedDistrictsInZone="addedDistrictsInZone"
        @getDistrictsInZones="getDistrictsInZones" :zona="zona" @update="update"></form-zone>
    </forms-panel>
    <forms-panel :panel="editRoute" @close="close">
      <form-router></form-router>
    </forms-panel>
    <div v-if="loading" class="fixed top-0 left-0 bg-[#00000099] w-full h-full z-[1001]">
      <div
        class="absolute top-[calc(50%-20px)] left-[calc(50%-20px)] w-[40px] aspect-square border-[4px] rounded-full border-r-black border-t-black border-b-black animate-spin">
      </div>
    </div>
  </div>
</template>

<script>
import ToolBarMap from './ToolBarMap.vue'

// Modalka

import FormHome from './FormHome.vue'
import FormsPanel from './FormsPanel.vue'
import FormCity from './FormCity.vue'
import FormStreet from './FormStreet.vue'
import FormVillages from './FormVillages.vue'
import FormDistrictAdd from './FormDistrictAdd.vue'
import FormDistrictEdit from './FormDistrictEdit.vue'
import FormAdd from './FormAdd.vue'
import FormZone from "./FormZone.vue";
import FormAddress from './FormAddress.vue';
import FormAddressEdit from "./FormAddressEdit.vue";
import FormRouter from './FormRoute.vue'


// Libraries 

import { mapActions } from "vuex";
import { mapState } from 'vuex';
import { debounce } from "debounce";
import axios from 'axios'
import { Icon } from 'leaflet';
import L from "leaflet";
import LRoutingMachine from "@/components//Map/LRoutingMachine.vue"
import {LMap, LTileLayer, LPolygon, LMarker, LIcon, LPopup} from 'vue2-leaflet'
import Vue2LeafletMarkerCluster from "vue2-leaflet-markercluster";



// style 

import '@/assets/leaflet.css'
import '@/assets/MarkerCluster.css';
import '@/assets/MarkerCluster.Default.css';
import 'leaflet/dist/leaflet.css'
import 'leaflet-draw'
import 'leaflet-draw/dist/leaflet.draw.css'

// Custom

import polygonCustom from '@/components/custom/polygonCustom.vue'
import zonaCustom from '@/components/custom/zonaCustom'
import addCustomPolygon from '@/components/custom/addCustomPolygon.vue'
import showPolygon from '@/components/custom/showPolygon.vue'
import markerIcon from '@/components/icon/markerIcon.vue'
import SearchSelect from "../SearchSelect.vue";
import styleMap from '@/util/styleMap'
import createLine from '@/components/custom/createLine.vue'

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

export default {
  components: {
    LMap,
    zonaCustom,
    showPolygon,
    addCustomPolygon,
    LTileLayer,
    polygonCustom,
    ToolBarMap,
    LPolygon,
    FormHome,
    LRoutingMachine,
    FormsPanel,
    FormCity,
    FormAdd,
    FormStreet,
    FormVillages,
    FormDistrictAdd,
    FormDistrictEdit,
    FormZone,
    LMarker,
    LIcon,
    "v-marker-cluster": Vue2LeafletMarkerCluster,
    FormAddress,
    FormAddressEdit,
    SearchSelect,
    FormRouter,
    LPopup,
    markerIcon,
    createLine
  },
  mixins: [styleMap],
  data() {
    return {
      url: "https://tile.openstreetmap.org/{z}/{x}/{y}.png",
      zoom: 13,
      center: {
        id: 6,
        name: "Грам (Душанбе)",
        lng: "68.78299713",
        lat: "38.55816663"
      },
      lineIsActive: false,
      addLocSpeedaddress: null,
      showContext: {},
      addHomeSteets: false,
      editHomes: false,
      addDistrictsData: null,
      routerIsActive: false,
      zona: null,
      latlng: [],
      districtData: [],
      drawResult: [],
      geojsonLayer: null,
      editVillageCity: false,
      homeCenterPolygon:  false,
      drawnItems: null,
      bounds: null,
      fastAddresses: [],
      selectHome: false,
      editStreets: false,
      editDistrict: false,
      map: null,
      waypoints: [],
      count: 0,
      countVillages: 0,
      pageX: 0,
      pageY: 0,
      editZones: false,
      addLoc: false,
      dist_id_street: null,
      propPolygon: [],
      district_id: 0,
      village_id: 0,
      propVillage: [],
      outsidePolygon: true,
      addVilldage: false,
      addFastAddress: false,
      editFastAddress: false,
      isMarkerActive: false,
      editRoute: false,
      editedZone: false,
      godColotInDistric: '',
      addedDistrictsInZone: [],
      addDistrict: false,
      editHouse: null,
      addAny: false,
      editFastHouse: null,
      houseCoordinates: [],
      fastAddressCoor: [],
      addresses: [],
      divisions: [],
      village: {},
      polygons: [],
      loadings: {
        coordinates: false,
        villages: false,
        addresses: false,
      },
      markerFastAddress: null,
      markerAddress: null,
      searchAddress: [],
      currentBounds: null,
      drawControl: null,
      selectedPolygonIndex: null,
      previousLayer: null,
    }
  },
  mounted() {
    this.map = this.$refs.map
    if(localStorage.getItem('divisions')) this.center = JSON.parse(localStorage.divisions)
    this.orderData()
    this.getAllCoordinates()
  },
  computed: {
    loading() {
      for (const key in this.loadings) {
        if (Object.hasOwnProperty.call(this.loadings, key)) {
          const element = this.loadings[key];
          if (element) {
            return true
          }
        }
      }
      return false
    },
    keymap() {
      return {
        'esc': () => {
          this.removeLine(),
          this.removeRouter()
        }
      }
    },
    ...mapState('district', ['districts']),
  },
  watch: {
    zoom(newValue) {
      if(newValue == 14) {
        this.onMoveEnd()
      }
    },
  },
  methods: {
    // Map edit language

    styleMap(name) {
      this.urls.forEach((e) => {
        if(e.id == name) {
          this.url = e.name
        }
      })
    },

    //  City boundaries edit

    editPolygon() {
      if(this.homeCenterPolygon) {
        if(!this.editVillageCity) {
          this.editVillageCity = true
          this.selectHome = false
          this.addDistrict = false
          this.editDistrict = false
          this.editStreets = false
          this.addLoc = false
          this.addVilldage = false
          this.addFastAddress = false
          this.editFastAddress = false
          this.selectPolygon(this.village)
        }else {
          this.editVillageCity = false
          this.cancelEdit(this.village)
          this.close(), 
          this.update()
        }
      }else {
        alert('Выберите из карты один из населенных пунктов.')
      }
    },


    cancelEdit(village) {
      const map = this.$refs.map.mapObject;
      this.count = 0
      this.drawnItems.clearLayers()
      map.removeLayer(this.drawnItems)
      this.geojsonLayer = null
      if(village) {
        this.selectPolygon(village)
      }
    },
    selectPolygon(village) {
      if (this.count < 1) {
        this.count++
        const map = this.$refs.map.mapObject;
        this.drawnItems = new window.L.FeatureGroup();
        map.addLayer(this.drawnItems);
        var drawControl = new window.L.Control.Draw({
          draw: {
            polygon: true,
            rectangle: true,
            circle: true,
            marker: true,
            polyline: true,
            circlemarker: true
          },
          edit: {
            featureGroup: this.drawnItems,
          }
        });

        drawControl.setPosition('bottomleft')
        map.addControl(drawControl);

        this.polygon[0].geometry.coordinates[0] = []
        this.propPolygon = JSON.stringify(village.latlngs)

        this.polygons.forEach((poly, index) => {
          if (poly.id === village.id) {
            this.polygons.splice(index, 1)
          }
        })

        village.latlngs.forEach(element => {
          this.polygon[0].geometry.coordinates[0].push([element.lng, element.lat])
        })

        map.on("draw:edited", (e) => {
          let layers = e.layers;
          layers.eachLayer((layer) => {
            this.propPolygon = layer._latlngs[0]
            this.propPolygon = []
            layer._latlngs[0].forEach(el => {
              this.propPolygon.push({ 'lat': el.lat, 'lng': el.lng, })
            })
            this.propPolygon = JSON.stringify(this.propPolygon)
          });
        })

        map.on('draw:editvertex', (e) => {
          this.drawResult = []
          e.layers.eachLayer((layer) => {
            this.drawResult.push({ 'lat': layer._latlng.lat, 'lng': layer._latlng.lng, })
          })
        });

        this.geojsonLayer = window.L.geoJson(this.polygon);
        this.geojsonLayer.getLayers()[0].addTo(this.drawnItems);
        document.querySelector('.leaflet-draw-edit-edit').click()
        this.geojsonLayer = null
      }
    },

    // Districts Edit Group New

    showDistricts() {
      if(this.homeCenterPolygon) {
        this.editZones = true
        setTimeout(() => {this.$refs.editPolygon.show()}, 0);
      }else {
        alert('Выберите из карты один из населенных пунктов.')
      }
    },

    removeDistricts() {
      this.editZones = !this.editZones
      this.$refs.editPolygon.clearPolygon()
    },

    editZonesInDist() {
      if(this.homeCenterPolygon) {
        if(this.editedZone) {
          this.$refs.zonaСhoosing.clearPolygon()
          this.close()
        }else {
          this.editedZone = !this.editedZone
          setTimeout(() => {this.$refs.zonaСhoosing.show()}, 0);
        }
      }else {
        alert('Выберите из карты один из населенных пунктов.')
      }
    },
    
    createDistrict() {
      if(this.homeCenterPolygon) {
        if(this.addDistrict) {
          let status = this.$refs.addDistricts.clearPolygon()
          if(status) {
            this.close()
            this.$refs.showPolygon.mainClear()
          }else {
            return 
          }
        }else {
          this.addDistrict = true
          setTimeout(() => {this.$refs.addDistricts.addDistricts(), this.$refs.showPolygon.show()}, 0);
        }
      }else {
        alert('Выберите из карты один из населенных пунктов.')
      }
    },

    // Houses, streets edit home add home edit streets

    addHome() {
      if (this.village_id != 0) {
        if(!this.editHomes) {
          this.editStreets = false
          this.addFastAddress = false
          this.editFastAddress = false
          this.addLoc = false
          this.editHomes = true
          this.getAddress()
        }else {
          this.editStreets = false
          this.addFastAddress = false
          this.editFastAddress = false
          this.addLoc = false
          this.editHomes = false
          this.addresses = []
          this.currentBounds = null
        }
      }
      else {
        this.$toast.open({
          message: "Выберите границу города",
          type: 'default',
          position: 'top-left'
        });
      }
    },

    getAddress() {
      this.loadings.addresses = true
      axios.get(`map-api/addresses/${this.village_id}`, { params: { bounds: this.currentBounds } })
        .then(res => {
          this.addresses = res.data
        })
        .catch(err => {
          console.log(err);
        }).finally(() => {
          this.loadings.addresses = false
        })
    },

    addLocation() {
      if (this.village_id != 0) {
        if(!this.addHomeSteets) {
          this.addLoc = true
          this.editDistrict = false
          this.addDistrict = false
          this.editorPolygon = false
          this.selectHome = false
          this.editStreets = false
          this.addVilldage = false
          this.addFastAddress = false
          this.editFastAddress = false
          this.addHomeSteets = true
          this.getAddress()
          this.addHouse()
        }else {
          this.addresses = []
          this.fastAddresses = []
          this.addHomeSteets = false
          this.addLoc = false
          this.currentBounds = null
          if(this.markerAddress) this.markerAddress.remove()
        }
      } else {
        this.$toast.open({
          message: "Выберите границу города",
          type: 'default',
          position: 'top-left'
        });
      }
    },


    addHouse() {

      const map = this.$refs.map.mapObject;

      map.on('click', (e) => {

        let latlng = e.latlng;

        if (!this.markerAddress) {
          this.markerAddress = L.circleMarker(latlng).addTo(map);

          this.setAddressCoordinatesToProps();
        } else {
          this.markerAddress.setLatLng(latlng);

          this.setAddressCoordinatesToProps();
        }

      })
    },

    addStreet() {
      if (this.village_id != 0) {
        if(!this.editStreets) {
          this.editStreets = true
          this.editDistrict = false
          this.addDistrict = false
          this.editorPolygon = false
          this.selectHome = false
          this.addLoc = false
          this.addVilldage = false
          this.addFastAddress = false
          this.editFastAddress = false
        }else {
          this.editStreets = false
        }
      } else {
        this.$toast.open({
          message: "Выберите границу города",
          type: 'default',
          position: 'top-left'
        });
      }
    },



    //  Quick Addresses edit add
    editFast() {
      if(this.village_id != 0) {
        if(!this.addLocSpeedaddress) {
          this.selectHome = false
          this.editorPolygon = false
          this.editStreets = false
          this.addLocSpeedaddress = true
          this.addDistrict = false
          this.editDistrict = false
          this.addVilldage = false
          this.addFastAddress = false
          this.eyeFastAdress()
          this.getAddress()
        }else {
          this.addLocSpeedaddress = false
          this.fastAddresses = []
          this.addresses = []
          this.editFastAddress = false
        }
      }else {
        this.$toast.open({
          message: "Выберите границу города",
          type: 'default',
          position: 'top-left'
        });
      }
    },
  
    eyeFastAdress() {
      axios.get(`map-api/fast-addresses/${this.village_id}`, { params: { bounds: this.currentBounds } })
        .then(res => {
          res.data.forEach((e) => {
            if(e.lat) this.fastAddresses.push(e)
          })
        })
        .catch(err => {
          console.log(err);
        })
    },

    selectFastHouse(id) {
      this.axios.get(`addresses/fast/addresses/${id}/edit`)
        .then((res) => {
          this.editFastHouse = res.data;
          let index = this.fastAddresses.findIndex(el => el.id === this.editFastHouse.id);
          this.fastAddresses[index].draggable = true;
        })
        .catch((error) => {
          console.log(error);
        })
      this.editorPolygon = false
      this.selectHome = false
      this.addDistrict = false
      this.editDistrict = false
      this.editStreets = false
      this.addLoc = false
      this.addVilldage = false
      this.addFastAddress = false
      this.editFastAddress = true
    },

    markerDragEndAddress(event) {
      this.editFastHouse.lat = event.target._latlng.lat
      this.editFastHouse.lng = event.target._latlng.lng
    },


    addFast() {
      if (this.village_id != 0) {
        if(!this.addFastAddress) {
          this.editorPolygon = false
          this.selectHome = false
          this.addDistrict = false
          this.editDistrict = false
          this.editStreets = false
          this.addLoc = false
          this.addVilldage = false
          this.addFastAddress = true
          this.editFastAddress = false
          this.fastAddress()
          this.eyeFastAdress()
        }else {
          this.addFastAddress = false
          if(this.markerFastAddress) this.markerFastAddress.remove()
          this.markerFastAddress = null,
          this.fastAddresses = []
        }
      }
      else {
        this.$toast.open({
          message: "Выберите границу города",
          type: 'default',
          position: 'top-left'
        });
      }
    },

    closeFormAddress() {
      this.addFastAddress = false
      if(this.markerFastAddress) this.markerFastAddress.remove()
      this.markerFastAddress = null,
      this.fastAddresses = []
    },

    // Router
    editRouter() {
      this.$toast.open({
          message: "Функционал маршрут в процессе разработки!",
          type: 'default',
          position: 'top-left'
        });
      //if(!this.routerIsActive) {
       // this.routerIsActive = true
       // this.editRoute = true
      //}else {
       // this.routerIsActive = false
        //this.editRoute = false
      //}
    },

    removeRouter() {
      this.routerIsActive = false
      this.editRoute = false
    },

    // Line
    lineCreate() {
      if(!this.lineIsActive) {
        this.lineIsActive = true
        setTimeout(() => {this.$refs.Line.showLine()}, 0);
      }else { 
        this.$refs.Line.clearLine()
        this.lineIsActive = false
      }
    },

    removeLine() {
        if(this.lineIsActive) {
          this.$refs.Line.clearLine()
          this.lineIsActive = false
        }
      },
    














    
    onMoveEnd() {
        let bounds = this.$refs.map.mapObject.getBounds();
        if (this.currentBounds && bounds.equals(this.currentBounds)) {
          return
        }

        this.currentBounds = bounds
        if(this.addHomeSteets || this.editHomes) {
          this.addressGetWithBounds(bounds)
        }
        if(this.addLocSpeedaddress) {
          this.fastAddressGetWithBounds(bounds)
          this.addressGetWithBounds(bounds)
        }
    },


    addressGetWithBounds: debounce(function (bounds) {
      axios.get(`map-api/addresses/${this.village_id}`, bounds ? { params: { bounds: this.currentBounds  } } : '')
        .then(res => {
          this.addresses = res.data
        })
        .catch(err => {
          console.log(err);
        })
    }, 350),
    fastAddressGetWithBounds: debounce(function (bounds) {
      axios.get(`map-api/fast-addresses/${this.village_id}`, bounds ? { params: { bounds: this.currentBounds }} : '' )
        .then(res => {
          this.fastAddresses = res.data
        })
        .catch(err => {
          console.log(err);
        })
    }, 350),
















    // this.eyeFastAdress()






























    // additional code

    orderData() {
      this.$http.get(`orders/data`)
      .then((res) => {
        this.divisions = res.data.divisions
      })
      .catch((err) => {
        console.log(err);
      })
    },

    getAllCoordinates() {
      this.loadings.coordinates = true
      axios.get('addresses/villages')
          .then(res => {
            this.polygons = res.data.map(element => {
              return {
                id: element.id,
                name: element.name,
                lat: element.lat,
                lng: element.lng,
                latlngs: JSON.parse(element.polygon),
                colorIcon: element.color,
                color: this.colors[3].color,
                weight: 3,
                city_id: element.city_id,
                city_name: element.city_name,
                village_type_id: element.village_type_id,
                village_type_name: element.village_type_name
              }
            })
          })
          .catch(err => {
            console.log(err);
          }).finally(() => {
            this.loadings.coordinates = false
          })
      },


      // Add strets or fast address
      fastAddress() {
      const map = this.$refs.map.mapObject;

      map.on('click', (e) => {
        if(this.addFastAddress || this.addHomeSteets) { 
          let latlng = e.latlng;
  
          if (!this.markerFastAddress) {
            this.markerFastAddress = L.circleMarker(latlng).addTo(map);
  
            this.setFastAddressCoordinatesToProps();
          } else {
            this.markerFastAddress.setLatLng(latlng);
  
            this.setFastAddressCoordinatesToProps();
          }
        }


      })
    },
      

      // delete 
      














    polygonDisabled() {
      if(!this.homeCenterPolygon) {
        this.homeCenterPolygon = true
      }else {
        this.homeCenterPolygon = false
      }
    },
    openPopup(event) {
      this.$refs.marker.forEach((e) => {
        if(e.latLng.lat == event.latlng.lat) {
          e.mapObject.openPopup(event.latlng)
        }
      })
    },
    changeStreetId(event) {
      localStorage.divisions = JSON.stringify(event)
    },
    handleChangeDataCenter(center, hasAddress) {
      this.isMarkerActive = hasAddress
      this.center.lng = center.lng
      this.center.lat = center.lat
    },
    async setWaypoints(waypoints) {
      this.$refs.route.removeRoute()
      this.waypoints = await waypoints
      console.log(this.waypoints);
      if (this.waypoints.length > 0) {
        this.$refs.route.add()
      }
    },
    searchAddresWithCoor() {
      this.$http.get(`https://msearch.gram.tj/nominatim/reverse?format=jsonv2&lat=${this.showContext.lat}&lon=${this.showContext.lng}`)
        .then(res => {
          this.searchAddress = res.data
          this.showContext = {}
          this.center = {
            lng: res.data.lon,
            lat: res.data.lat
          }
          this.isMarkerActive = true
        }).catch((err) => {
          console.log(err);
        })
    },
    handle(event) {
      this.showContext = {
        lat: event.latlng.lat,
        lng: event.latlng.lng
      }
    },
    ...mapActions(['updateMapInstance']),
    ...mapActions('district', ['sendCreationEvent', 'getAllDistricts', 'makesTheArrayOfDistrictsEmpty']),
    setMapInstanceToStore(map) {
      this.updateMapInstance(map);
    },
    initializeDrawControl() {
      this.drawControl = new L.Control.Draw({
        draw: {
          polygon: true,
          marker: false,
          circle: false,
          rectangle: false,
          polyline: false,
          circlemarker: false,
        },
      });

      this.$refs.map.mapObject.addControl(this.drawControl);
    },
    
    
    fastAddressDataChange(data) {
      this.editFastHouse[data.field] = data.value
    },
    homeDataChange(data) {
      this.editHouse[data.field] = data.value
    },
    zoomUpdated(zoom) {
      this.zoom = zoom;
    },
    eyeDistricts() {
      this.editZones = !this.editZones
    },
    centerUpdated(center) {
      this.center = center;
    },
    boundsUpdated(bounds) {
      this.bounds = bounds;
    },
    zoomPlus() {
      this.zoom++
    },
    zoomMinus() {
      this.zoom--
    },
    
    getDistrictsInZones(districts) {
      if (districts.length > 0) {
        this.godColotInDistric = districts[0].color
        districts.forEach(el => {
          this.districts.forEach(zone => {
            if (el.id == zone.id) {
              this.addedDistrictsInZone.push(zone)
            }
          })
        })
      }
      else {
        this.godColotInDistric = ''
        this.districts.forEach(zone => {
          zone.opacity = 0.3
        })
      }
    },
    close() {
      this.selectHome = false
      this.editorPolygon = false
      this.editStreets = false
      this.addLoc = false
      this.addDistrict = false
      this.editDistrict = false
      this.addVilldage = false
      this.addFastAddress = false
      this.editFastAddress = false
      this.editedZone = false
      this.editRoute = false
      this.remove()
    },
    remove() {
      console.log('map');
      
    },
    openContextMenu(e) {
      this.$refs.menu.open(e);
      this.pageX = e.containerPoint.x;
      this.pageY = e.containerPoint.y;
    },
    centerToAdressError(adress) {
      this.center = [adress.lat, adress.lng]
    },
    
    
    selectHouse(id) {
      this.selectHome = false
      this.axios.get(`addresses/addresses/${id}/edit`)
        .then(response => {
          this.editHouse = response.data;
          let index = this.addresses.findIndex(el => el.id === this.editHouse.id);
          this.addresses[index].draggable = true;
          this.selectHome = true
          this.editorPolygon = false
          this.addDistrict = false
          this.editDistrict = false
          this.editStreets = false
          this.addLoc = false
          this.addVilldage = false
          this.addFastAddress = false
          this.editFastAddress = false
        })
        .catch(error => {
          console.log(error);
        })
    },
    
    addCircleMarker() {
      document.querySelector('.leaflet-draw-draw-circlemarker').click()
      this.addAny = false
    },
    removeFastMarker() {
      this.eyeFastAdress()

      if (this.markerFastAddress) {
        this.markerFastAddress.remove();
        this.markerFastAddress = null;
        this.fastAddressCoor = '';
      }
    },
    removeCircleMarker() {
      document.querySelector('.leaflet-draw-edit-remove').click()
      document.querySelector('[title="Clear all layers"]').click()
    },
    update() {
      this.$router.go()
      let center = JSON.stringify([this.center.lat, this.center.lng])
      localStorage.setItem("center", center)
      localStorage.setItem("zoom", this.zoom)
    },
    editPolygonWithIndex(id) {
      this.editDistrict = false
      this.district_id = id
      axios.get(`addresses/districts/${id}/edit`)
      .then((res) => {
        this.districtData = res.data
        this.editDistrict = true
      })
      .catch((error) => {
        console.log(error);
      })
    },
    postDistrict(district) {
      axios.patch(`addresses/districts/${this.district_id}`, district)
        .then(res => {
          this.$toast.open({
            message: res.data.message,
            type: 'success',
            position: 'top'
          });
          this.update()
          console.log(res);
        })
        .catch(err => {
          console.log(err);
        })
    },
    selectVillages(id) {
      // this.map.mapObject.on('click',(e) => {
      //   console.log(e);
        
      // })
      if(!this.editorPolygon) {
        if(this.editZones) {
          this.removeDistricts()
        }
        this.homeCenterPolygon = true
        this.makesTheArrayOfDistrictsEmpty()
        this.village_id = id.id
        this.polygons.forEach(el => {
          el.color = '#3374FF'
          el.weight = 3
          if(el.id == id.id) {
              el.weight = 4
              el.color = '#ff0000'
              this.village = el
          }
        })
        this.getAllDistricts(this.village_id)
      }
    },
    
    fastAddressAdded(value) {
      this.fastAddresses.push(value);
    },
    addressAdded(value) {
      this.addresses.push(value)
    },
    postPolygon(coordinates) {
      document.querySelector('.leaflet-draw-actions li:first-child a').click()
      axios.get(`addresses/villages/${this.village.id}/edit`)
        .then(res => {
          let editedElement = res.data
          editedElement.polygon = JSON.stringify(coordinates)
          axios.patch(`addresses/villages/${this.village.id}`, editedElement)
            .then(() => {
              this.update()
            })
            .catch(err => {
              console.log(err);
            })
        })
        .catch((err) => {
          console.log(err);
        })
    },


    eyeAddreses() {
      this.getAddress()
    },
    addVillage() {
      if (this.countVillages < 1) {
        this.countVillages++
        this.addVilldage = true
        this.editorPolygon = false
        this.selectHome = false
        this.addDistrict = false
        this.editDistrict = false
        this.editStreets = false
        this.addLoc = false
        this.addFastAddress = false
        this.editFastAddress = false
        const map = this.$refs.map.mapObject;
        var drawnItems = new window.L.FeatureGroup()
        map.addLayer(drawnItems)

        map.on('draw:created', (e) => {
          var type = e.layerType,
            layer = e.layer;
          let zoneMap = []
          layer._latlngs[0].forEach(el => {
            zoneMap.push({ 'lat': el.lat, 'lng': el.lng, })
          })
          this.propVillage = JSON.stringify(zoneMap)
          console.log(this.propVillage);
          this.addVilldage = true
          if (type === 'marker') {
            layer.bindPopup('A popup!');
          }

          drawnItems.addLayer(layer);
        });
        const polygon_options = {
          showArea: false,
          shapeOptions: {
            stroke: true,
            color: '#6e83f0',
            weight: 4,
            opacity: 0.5,
            fill: true,
            fillColor: null,
            fillOpacity: 0.2,
            clickable: true
          }
        }
        var polygonDrawer = new window.L.Draw.Polygon(map, polygon_options);
        polygonDrawer.enable();
      }
    },
    setFastAddressCoordinatesToProps() {
      let coor = [this.markerFastAddress._latlng.lat, this.markerFastAddress._latlng.lng];
      this.fastAddressCoor = JSON.stringify(coor);
    },
    setAddressCoordinatesToProps() {
      let coor = [this.markerAddress._latlng.lat, this.markerAddress._latlng.lng];
      this.houseCoordinates = JSON.stringify(coor)
    },
    
  },

}
</script>

<style>
.search {
  width: 400px;
  z-index: 9998;
}
.search_division {
  z-index: 9999;
}
.vs__search,
.vs__search:focus {
  border: none;
}
</style>